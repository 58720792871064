.searchPoste{
    width: 100%;
}
.searchIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3275c9;
    border-radius: 50%;
    color: #fff;

}
.weProvide .titleFont {
    max-width: 45%;
  }
  .weProvideRow {
    align-items: center;
  }
  .weProvideBtnCol {
    text-align: right;
  }
  .weProBox::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    background-color: #2966b2;
    transition: 0.3s ease;
  }
  .weProBox:hover::before {
    width: 100%;
  }
  .weProBox {
    position: relative;
    border: 1px solid rgba(112, 112, 112, 0.3);
    display: inline-flex;
    padding: 15px 0px;
    align-items: center;
    cursor: pointer;
    margin: 13px 0px;
    transition: 0.5s ease;
    border-radius: 15px;
  }
  .weProBoxImg {
    padding: 5px;
    border: 1px solid rgba(112, 112, 112, 0.3);
    transition: 0.4s ease;
    margin: 20px;
  }
  .weProBox img {
    margin: 0;
    max-width: unset;
    width: 50px;
    height: 50px;
    transition: 0.4s ease;
  }
  .weProBoxText {
    margin-right: 20px;
  }
  .weProBoxText h6 {
    font-size: 20px;
    color: #000;
    transition: 0.4s ease;
    font-weight: 600;
  }
  .weProBox:hover img {
    filter: none;
  }
  .weProBox:hover .weProBoxImg {
    /* background-color: #2966b2; */
    border-radius: 50%;
    border: 1px solid #2966b2;
  }
  .weProBox:hover .weProBoxText h6 {
    color: #2966b2;
  }
  .weProBox:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #fff;
  }
  .weProBox .paragraph{
    line-height: 24px;
  }
.paginationJob nav ul{
    justify-content: center;
}
.NosMission{
  display: flex;
  justify-content: center;
  align-items: center;
}
.bilan {
  background-image: url('../../assets/Images/Banner/origami.png');
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}
.BilanImg{
  display: flex;
  justify-content: center;
  align-items: center;
}
.BilanImg img{
  border-radius: 15px;
}