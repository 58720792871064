.beffectClient .bimg {
    display: block;
    position: relative;
    overflow: hidden;
    -webkit-transition: all 0.9s;
    -moz-transition: all 0.9s;
    -o-transition: all 0.9s;
    transition: all 0.9s;
  }
  .beffectClient img {
    max-width: 100%;
    width: 100%;
    -webkit-transition: all 0.9s;
    -moz-transition: all 0.9s;
    -o-transition: all 0.9s;
    transition: all 0.9s;
  }
  .beffectClient .bimg::after {
    background: rgba(0, 0, 0, 0.5);
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    /* -webkit-transition: all 0.4s ease-in-out 0s; */
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: 0px;
  }
  .ourClientCard:hover .beffectClient .bimg::after {
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  .ourClientMain .beffectClient .bimg::after {
    border-radius: 0px 0px 0px 0px;
  }
  .ourClientMain {
    margin: 40px 0px 0px 0px;
  }
  .ourClientMain .card {
    box-shadow: none;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.08);
    border-radius: 0px;
    border: none;
    margin-bottom: 15px;
  }
  .ourClientMain img {
    border-radius: 0px 0px 0px 0px;
  }
  .ourClientMain .slick-next {
    display: block;
    bottom: 0;
    top: -50px;
    right: 20px;
    left: auto;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 0px !important;
    background-color: #4799ff;
    opacity: unset;
    transition: 0.5s ease;
    box-shadow: 0 0 10px #e2e1e1;
  }
  .ourClientMain .slick-prev {
    display: block;
    bottom: 0;
    top: -50px;
    right: 70px;
    left: auto;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 0px !important;
    background-color: #4799ff;
    opacity: unset;
    transition: 0.5s ease;
    box-shadow: 0 0 10px #e2e1e1;
  }
  .ourClientMain .slick-next:hover,
  .ourClientMain .slick-prev:hover {
    background-color: #ffffff;
  }
  .ourClientMain .slick-next::before {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 25px;
    opacity: 1;
    transition: 0.5s ease;
  }
  .ourClientMain .slick-prev::before {
    content: "\f104";
    font-family: FontAwesome;
    font-size: 25px;
    opacity: 1;
    transition: 0.5s ease;
  }
  .ourClientMain .slick-arrow:hover::before {
    color: black;
  }
  .ourClientMain h2 {
    font-size: 18px;
  }
  .ourClientMain h6 {
    font-size: 16px;
  }
  
  .ourClientCard {
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .ourClientIcon {
    position: absolute;
    top: 0;
    left: -15px;
    margin: 0 auto;
    transition: 0.5s ease;
    opacity: 0;
  }
  
  .ourClientCard:hover .ourClientIcon {
    opacity: 1;
    left: 0;
    /* transition: 0.5s ease; */
  }
  .ourClientIcon li {
    width: 35px;
    font-size: 18px;
    background-color: white;
    margin: 10px;
    text-decoration: none;
    color: black;
    border-radius: 0px;
    padding: 3px;
    transition: 0.4s ease;
  }
  .ourClientIcon li:hover {
    background-color: #9b0000;
  }
  .ourClientIcon li:hover a {
    color: #fff;
  }
  .ourClientIcon li a {
    color: #000;
    padding: 4px;
    transition: 0.4s ease;
  }
  
  .ourClientIcon ul li:nth-child(1) {
    transition: 1s;
  }
  .ourClientIcon ul li:nth-child(2) {
    transition: 1.3s;
  }
  .ourClientIcon ul li:nth-child(3) {
    transition: 1.6s;
  }
  .ourClientIcon ul li:nth-child(4) {
    transition: 1.9s;
  }
  
  .ourClientMain .card-title {
    color: #000000;
    font-size: 18px;
    text-align: center;
    line-height: 35px;
    transition: 0.4s ease;
    cursor: pointer;
    margin: 0;
  }
  .ourClientMain .card-title:hover {
    color: #9b0000;
  }
  .ourClientMain .card-subtitle {
    font-size: 16px;
    color: #666666;
    text-align: center;
    line-height: 30px;
  }
  .ourClientIcon{
    left: -15px;
    margin: 0 auto;
    opacity: 0;
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    transition: .5s ease;
  }
  .ourClientIcon{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .ourClientIcon p{
      width: 80%;
  }
  .contactUsBanner {
    background-attachment: fixed;
    background-image: url("../../assets/Images/ContactUs/ContactUsBackgroundBanner.png");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-position: center center;
    background-size: 100% 100%;
    padding: 150px 0px;
}
@media (min-width: 320px) and (max-width: 575px) {
  .contactUsBanner{
    padding: 80px 0px 30px 0px;
  }
}